// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid, CircularProgress } from '@mui/material';
import logo from '../logo-white.png';
import { useSnackbar } from '../contexts/SnackbarContext';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const hasToken = !!localStorage.getItem('token'); // Check if token exists in local storage
  const hasUser = !!localStorage.getItem('user'); // Check if user exists in local storage

  useEffect(() => {
    if (hasToken && hasUser) {
      navigate('/dashboard');
    }
  }, [hasToken, hasUser, navigate]);

  const handleLogin = async () => {
    setError('');
    setLoading(true);

  fetch(process.env.REACT_APP_API_URL + '/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then(response => response.json())
    .then(async data => {
      if (data.token && data.user) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('accountingYear', JSON.stringify(data.user.company.accounting_years[0].id));
        showSnackbar('Velkommen tilbage ' + data.user.name, 'success');
        navigate('/dashboard');
      } else {
        setError('Forkert email eller adgangskode.');
        setLoading(false);
      }
    })
    .catch(error => {
      console.error('Login', error);
      setError('Der opstod en fejl under login, kontakt venligst support.');
      setLoading(false);
    });
};

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container spacing={0} sx={{ height: '100vh', margin: 0, padding: 0 }}>
        {/* Branding Section */}
        <Grid item xs={12} md={6} sx={{ backgroundColor: 'primary.main', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={logo} alt={process.env.REACT_APP_COMPANY_NAME} style={{ maxWidth: '100%', height: 'auto' }} />
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>
  
        {/* Content Section */}
        <Grid item xs={12} md={6} px={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', maxWidth: '530px' }}>
            <Typography component="h1" variant="h5" mt={2}>
              Log ind
            </Typography>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Adgangskode"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin();
                  }
                }}
                disabled={loading}
              />
              <Button
                type="button"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 1, mb: 1 }}
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Log ind'}
              </Button>
              {error && (
                <Typography variant="body2" color="error" sx={{ mt: 1, mb: 1 }}>
                  {error}
                </Typography>
              )}
              <Button color="primary" size="small" onClick={() => navigate('/glemt-adgangskode')}>
                Glemt adgangskode?
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="textSecondary">
                Er din virksomhed ikke oprettet? {' '}
                <Button color="primary" onClick={() => navigate('/opret-virksomhed')}>
                  Opret virksomhed
                </Button>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
