// src/pages/Dashboard.js
import React from 'react';
import { Box, Button, Container, Typography, Grid, Paper, Skeleton, Avatar, Divider, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FunctionsIcon from '@mui/icons-material/Functions';
import FactoryIcon from '@mui/icons-material/FactoryOutlined';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeterOutlined';
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboardOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [components, setComponents] = useState([]); 
  const [loading, setLoading] = useState(true);
  const componentColors = ['#54857e', '#006a61', '#004d46'];
  const [allowReport, setAllowReport] = useState(false);
  const [scope1, setScope1] = useState(0.0);
  const [scope1Complete , setScope1Complete] = useState(false);
  const [scope2, setScope2] = useState(0.0);
  const [scope2Complete , setScope2Complete] = useState(false);
  const [turnover, setTurnover] = useState(0.0);
  const [turnoverComplete , setTurnoverComplete] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/me/company/components?accounting_year_id=' + localStorage.getItem('accountingYear'), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setComponents(data);
        setLoading(false);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <Container sx={{ my: 4 }}>
       <Box sx={{ my: 4 }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h3" color="primary" component="h1" m="0" gutterBottom>
              {user.company.name}
            </Typography>
            <Typography variant="subtitle1" color="primary" sx={{ textTransform: 'uppercase' }}>
              {user.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} container justifyContent="flex-end">
          {!allowReport ? (
            <Tooltip 
              title="Udfør Environmental opgave for at se din rapport" 
              placement="top"
            >
              <span>
                <Button size="large" variant="contained" color="primary" disabled>
                  <ContentPasteIcon sx={{ mr: 1 }} />
                  Vis rapport
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button size="large" variant="contained" color="primary" onClick={() => navigate('/rapport')}>
              <ContentPasteIcon sx={{ mr: 1 }} />
              Vis rapport
            </Button>
          )}
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" color="primary" component="h2" gutterBottom>
            <SpaceDashboardIcon color="primary" sx={{ mr: 1, fontSize: '2rem', marginBottom: '-3px' }} />
            Opgaver
          </Typography>
          <Typography variant="body1">
            Velkommen til DitESG. Udfyld nedenstående opgaver for at se din rapport.
          </Typography>
          {loading ? (
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="rectangular" width={80} height={36} />
              </Paper>
              <Paper sx={{ p: 2, mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="rectangular" width={80} height={36} />
              </Paper>
              <Paper sx={{ p: 2, mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="rectangular" width={80} height={36} />
              </Paper>
            </Grid>
          ) : (
            components.map((component, index) => {
              const componentName = component.name.split(' - ');
              const companyComponentLog = component.company_component_logs && component.company_component_logs[0];
              if (
                allowReport === false
                && componentName[0] === 'E'
                && companyComponentLog
                && companyComponentLog.is_completed === 1
              ) {
                setAllowReport(true);
              }

              if (companyComponentLog && companyComponentLog.data) {
                const data = JSON.parse(companyComponentLog.data);
                if (componentName[0] === 'E') {
                  if (scope1 === 0 && data.scope1 && data.scope1 > 0) {
                    setScope1(data.scope1);
                  }

                  if (scope2 === 0 && data.scope2 && data.scope2 > 0) {
                    setScope2(data.scope2);
                  }
                  
                  if (component.company_component_logs[0].is_completed === 1 && scope1Complete === false && scope2Complete === false) {
                    setScope1Complete(true);
                    setScope2Complete(true);
                  }
                }

                if (componentName[0] === 'G') {
                  if (turnover === 0 && data.turnover && data.turnover > 0 && scope1 > 0 && scope2 > 0) {
                    setTurnover((scope1 + scope2) / data.turnover);
                  }

                  if (component.company_component_logs[0].is_completed === 1 && turnoverComplete === false) {
                    setTurnoverComplete(true);
                  }
                }
              }

              return (
                <Grid item xs={12} key={component.id}>
                  <Paper variant="outliend" sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #006A61', mt: 4 }}>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Avatar sx={{ bgcolor: componentColors[index % componentColors.length], mr: 2 }} variant="rounded">
                          {componentName[0]}
                        </Avatar>
                        <Typography variant="h5" component="h2" color="primary">{componentName[1]}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="body2" color="textSecondary">
                          Senest opdateret: 
                          {
                            (companyComponentLog && companyComponentLog.updated_at) 
                              ? ' ' + new Date(companyComponentLog.updated_at).toLocaleDateString() 
                              : ' Aldrig'
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Button 
                      variant={
                        (companyComponentLog && companyComponentLog.updated_at) 
                          ? (companyComponentLog.is_completed === 1 ? 'outlined' : 'contained') 
                          : 'contained'
                      } 
                      color={
                        (companyComponentLog && companyComponentLog.updated_at) 
                          ? 'secondary' 
                          : 'primary'
                      } 
                      onClick={() => navigate('/form', { state: { context: component } })}
                    >
                      {
                        (companyComponentLog && companyComponentLog.updated_at) 
                          ? (companyComponentLog.is_completed === 1 ? 'Genåbn' : 'Fortsæt') 
                          : 'Start'
                      }
                    </Button>
                  </Paper>
                </Grid>
              )
            })
          )}
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 5, borderColor: '#88b3af', display: { xs: 'none', md: 'block' } }} />
        <Grid item xs={12} md={5} sx={{ mt: { xs: 2, md: 0 } }}>
          <Typography variant="h4" color="primary" component="h2" gutterBottom>
            <BarChartIcon color="primary" sx={{ mr: 1, fontSize: '2rem', marginBottom: '-3px' }} />
            Nøgletal
          </Typography>
          <Typography variant="body1">
            Nøgletal for udledning baseret på de indtastede data.
          </Typography>

          <Grid item xs={12} key="scope1">
            <Paper variant="outlined" sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #006A61', mt: 4, backgroundColor: scope1 === 0 ? 'transparent' : (scope1 > 0 && !scope1Complete ? '#FFF4E5' : 'rgba(76, 181, 143, 0.3)') }}>
              <Box sx={{ width: 'auto' }}>
                <FactoryIcon color="primary" sx={{ mr: 1, fontSize: '4rem' }} />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h5" component="h2" color="primary">Scope 1 - Direkte emissioner</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FunctionsIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    {scope1Complete ? (
                      <>
                        <strong>{scope1.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> <span style={{ opacity: 0.7, fontSize: '0.8em' }}>Kg CO2e</span>
                      </>
                    ) : (
                      "Udfør Environment opgave"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} key="scope2">
            <Paper variant="outlined" sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #006A61', mt: 4, backgroundColor: scope2 === 0 ? 'transparent' : (scope2 > 0 && !scope2Complete ? '#FFF4E5' : 'rgba(76, 181, 143, 0.3)')  }}>
              <Box sx={{ width: 'auto' }}>
                <ElectricMeterIcon color="primary" sx={{ mr: 1, fontSize: '4rem' }} />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h5" component="h2" color="primary">Scope 2 - Indirekte emissioner</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FunctionsIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    {scope2Complete ? (
                      <>
                        <strong>{scope2.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> <span style={{ opacity: 0.7, fontSize: '0.8em' }}>Kg CO2e</span>
                      </>
                    ) : (
                      "Udfør Environment opgave"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} key="turnover">
            <Paper variant="outlined" sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #006A61', mt: 4, backgroundColor: turnover === 0 ? 'transparent' : (turnover > 0 && !turnoverComplete ? '#FFF4E5' : 'rgba(76, 181, 143, 0.3)') }}>
              <Box sx={{ width: 'auto' }}>
                <PaymentsIcon color="primary" sx={{ mr: 1, fontSize: '4rem' }} />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h5" component="h2" color="primary">Kg CO2e per omsat million</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FunctionsIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    {turnoverComplete ? (
                      <>
                        <strong>{turnover.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> <span style={{ opacity: 0.7, fontSize: '0.8em' }}>Kg CO2e</span>
                      </>
                    ) : (
                      "Udfyld omsætning under Governance"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
