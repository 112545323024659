// src/pages/StartPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography, Box, Grid } from '@mui/material';
import logo from '../logo-white.png';

const StartPage = () => {
  const navigate = useNavigate();

  const hasToken = !!localStorage.getItem('token'); // Check if token exists in local storage
  const hasUser = !!localStorage.getItem('user'); // Check if user exists in local storage

  useEffect(() => {
    if (hasToken && hasUser) {
      navigate('/dashboard');
    }
  }, [hasToken, hasUser, navigate]);

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container spacing={0} sx={{ height: '100vh', margin: 0, padding: 0 }}>
        {/* Branding Section */}
        <Grid item xs={12} md={6} sx={{ backgroundColor: 'primary.main', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={logo} alt={process.env.REACT_APP_COMPANY_NAME} style={{ maxWidth: '100%', height: 'auto' }} />
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>
  
        {/* Content Section */}
        <Grid item xs={12} md={6} px={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Typography component="h1" variant="h5" mt={2}>
              DitESG portalen
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: 2 }}>
              Velkommen til DitESG portalen.<br />
              Log ind eller opret virksomhedskonto for at påbegynde ESG-rapportering.
            </Typography>
            
            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => navigate('/logind')}
              >
                Log ind
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => navigate('/opret-virksomhed')}
              >
                Opret virksomhed
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StartPage;
