// src/pages/CreateCompany.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid, Stepper, Step, StepLabel, FormControl, Autocomplete, CircularProgress } from '@mui/material';
import logo from '../logo-white.png';
import { useSnackbar } from '../contexts/SnackbarContext';

const CreateCompany = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [companyData, setCompanyData] = useState({ name: '', address: '', city: '', zip: '', country: '', phone: '', email: '', vat_number: '', bank_vendor_id: '', start_date: '', industry_code: '', industry_description: '' });
  const [userData, setUserData] = useState({ user_email: '', user_password: '', user_fullname: '', user_jobtitle: '', user_password_confirm: '' });
  const [cvrValid, setCvrValid] = useState(false);
  const [cvrError, setCvrError] = useState('');
  const [banks, setBanks] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const { showSnackbar } = useSnackbar();

  const steps = ['CVR nummer', 'Virksomhed', 'Bruger'];
  const hasToken = !!localStorage.getItem('token'); // Check if token exists in local storage
  const hasUser = !!localStorage.getItem('user'); // Check if user exists in local storage

  useEffect(() => {
    if (hasToken && hasUser) {
      navigate('/dashboard');
    }
  }, [hasToken, hasUser, navigate]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/bankvendors', {
      headers: {
        'Content': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setBanks(data);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    if (userData.user_password !== userData.user_password_confirm) {
      setPasswordConfirmError('Adgangskoderne er ikke ens');
      setLoading(false);
      return;
    }

    const now = new Date();
    const lastYear = now.getFullYear() - 1;
    const accountingYearStartDate = new Date(lastYear, 0, 1); // January 1st of last year
    const accountingYearEndDate = new Date(lastYear, 11, 31);

    const mergedData = {
      ...companyData,
      ...userData,
      ...{ accounting_year_start_date: formatDate(accountingYearStartDate), accounting_year_end_date: formatDate(accountingYearEndDate) }
    };
    
    fetch(process.env.REACT_APP_API_URL + '/companies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: 
        JSON.stringify(mergedData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.token && data.user) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('accountingYear', JSON.stringify(data.user.company.accounting_years[0].id));
          showSnackbar('Velkommen ' + data.user.name, 'success');
          navigate('/dashboard');
        } else {
          console.error('Create Company', data);
          // Get error message from each in data.
          let errorMessage = '';
          for (const key in data) {
            errorMessage += data[key][0] + '\r\n';
          }
          setError('Der opstod en fejl:\r\n' + errorMessage);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Create Company', error);
        setError('Der opstod en fejl under oprettelse af virksomheden, kontakt venligst support.');
        setLoading(false)
      });
  };

  const handleCvrChange = async (event) => {
    const value = event.target.value;
    setCompanyData({ ...companyData, vat_number: value });
    if (value.length === 8) {
      fetch(process.env.REACT_APP_API_URL + '/cvrapi?cvr=' + value)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Der opstod en fejl ved kontakt til CVR registeret: ${response.statusText}`);
          }
          return response.json();
        })
        .then(data => {
          if (data && !data.error) {
            if (data.companyExists === false) {
              setCompanyData({
                ...companyData,
                name: data.name,
                address: data.address,
                city: data.city,
                zip: data.zipcode,
                country: 'DK',
                phone: data.phone,
                email: data.email,
                vat_number: data.vat,
                start_date: data.startdate,
                industry_code: data.industrycode,
                industry_description: data.industrydesc
              });
              setCvrValid(true);
              setCvrError('');
            } else {
              setCvrValid(false);
              setCvrError('Virksomheden er allerede oprettet hos os');
            }
          } else {
            setCvrValid(false);
            setCvrError('Virksomhed ikke fundet i CVR registeret');
          }
        })
        .catch(error => {
          setCvrValid(false);
          setCvrError('Der opstod en fejl under validering af CVR nummeret, kontakt venligst support (' + error.message + ')');
        }
      );
    } else {
      setCvrValid(false);
    }
  };

  const handlePasswordConfirmChange = (password, passwordConfirm) => {
    if (passwordConfirm !== '' && password !== passwordConfirm) {
      setPasswordConfirmError('Adgangskoderne er ikke ens');
    } else {
      setPasswordConfirmError('');
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box component="form" onSubmit={handleNext} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyVatNumber"
              label="CVR nummer"
              name="companyVatNumber"
              autoFocus
              value={companyData.vatNumber}
              onChange={handleCvrChange}
              error={!!cvrError}
              helperText={cvrError}
            />
            <Button type="submit" size="large" fullWidth variant="contained" color="primary" sx={{ mt: 1, mb: 2 }} disabled={!cvrValid}>
              Næste
            </Button>

            <Typography variant="body2" color="textSecondary">
              Har du allerede en bruger? {' '}
              <Button color="primary" onClick={() => navigate('/logind')}>
                Log ind
              </Button>
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box component="form" onSubmit={handleNext} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Virksomhedsnavn"
              name="companyName"
              value={companyData.name}
              disabled
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="companyAddress"
              label="Adresse"
              name="companyAddress"
              value={`${companyData.address}, ${companyData.zip} ${companyData.city}`}
              disabled
            />

            <FormControl fullWidth margin="normal">
              <Autocomplete
                disablePortal
                options={banks}
                value={banks.find((bank) => bank.id === companyData.bank_vendor_id) || null}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                onChange={(e, newValue) => {
                  setCompanyData({ ...companyData, bank_vendor_id: newValue?.id ?? '' });
                }}
                renderInput={(params) => <TextField {...params} label="Bank" required />}
              />
            </FormControl>

            <Button type="submit" size="large" fullWidth variant="contained" color="primary" sx={{ mt: 1, mb: 2 }}>
              Opret virksomhed
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="fullname"
              label="Navn"
              name="fullname"
              value={userData.user_fullname}
              onChange={(e) => setUserData({ ...userData, user_fullname: e.target.value })}
              disabled={loading}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email adresse"
              name="email"
              type="email"
              value={userData.user_email}
              onChange={(e) => setUserData({ ...userData, user_email: e.target.value })}
              disabled={loading}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Adgangskode"
              name="password"
              type="password"
              value={userData.user_password}
              helperText="Adgangskoden skal være mindst 8 tegn lang"
              onChange={(e) => {
                setUserData({ ...userData, user_password: e.target.value });
                handlePasswordConfirmChange(e.target.value, userData.user_password_confirm);
              }}
              disabled={loading}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="user_password_confirm"
              label="Bekræft adgangskode"
              name="user_password_confirm"
              type="password"
              onChange={(e) => {
                setUserData({ ...userData, user_password_confirm: e.target.value });
                handlePasswordConfirmChange(userData.user_password, e.target.value);
              }}
              error={!!passwordConfirmError}
              helperText={passwordConfirmError}
              disabled={loading}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="jobtitle"
              label="Jobtitel"
              name="jobtitle"
              value={userData.user_jobtitle}
              onChange={(e) => setUserData({ ...userData, user_jobtitle: e.target.value })}
              disabled={loading}
            />

            <Button type="submit" size="large" fullWidth variant="contained" color="primary" sx={{ mt: 1, mb: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Opret bruger'}
            </Button>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
          </Box>
        );
      default:
        return 'Der opstod en fejl';
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container spacing={0} sx={{ height: '100vh', margin: 0, padding: 0 }}>
        {/* Branding Section */}
        <Grid item xs={12} md={6} sx={{ backgroundColor: 'primary.main', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={logo} alt={process.env.REACT_APP_COMPANY_NAME} style={{ maxWidth: '100%', height: 'auto' }} />
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid item xs={12} md={6} px={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <Typography component="h1" variant="h5" mb={3} mt={2}>
              Opret konto
            </Typography>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent(activeStep)}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateCompany;