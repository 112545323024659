import React, { useEffect, useState } from 'react';

const Report = () => {
    const accountingYearId = localStorage.getItem('accountingYear');
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/me/company/report/${accountingYearId}/show`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accept': 'application/pdf'
                    }
                });

                setIsLoading(false);
                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    setPdfUrl(url);
                } else {
                    console.error('Error fetching PDF:', response.statusText);
                    setError('Der opstod en fejl: ' + response.statusText);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Der opstod en ukendt fejl');
            }
        };

        fetchPdf();
    }, [accountingYearId]);

    return (
        <>
            {isLoading ? (
                <p>Loading PDF...</p>
            ) : (
                <>
                    {pdfUrl ? (
                        <iframe
                            src={pdfUrl}
                            style={{ width: '100%', height: 'calc(100vh - 73px)' }}
                            frameBorder="0"
                            title="Report"
                        />
                    ) : (
                        <p>{error}</p>
                    )}
                </>
            )}
        </>
    );
};

export default Report;