import React, { useState, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Settings from './pages/Settings';
import FormPage from './pages/FormPage';
import Login from './pages/Login';
import CreateCompany from './pages/CreateCompany';
import ForgotPassword from './pages/ForgotPassword';
import Report from './pages/Report';
import StartPage from './pages/StartPage';
import ProtectedRoute from './components/ProtectedRoute';
import { SnackbarProvider } from './contexts/SnackbarContext';
import ResetPassword from './pages/ResetPassword';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [isDataModified, setIsDataModified] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#006A61',
      },
      secondary: {
        main: '#4cb58f',
      },
    }
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDataModified) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);

    // When user presses back/forward button in browser
    window.onpopstate = (event) => {
      if (isDataModified) {
        setIsDataModified(false);
      }
    };
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDataModified]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Router>
          <Navbar onToggleDarkMode={toggleDarkMode} isDataModified={isDataModified} setIsDataModified={setIsDataModified} />
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/logind" element={<Login />} />
            <Route path="/glemt-adgangskode" element={<ForgotPassword />} />
            <Route path="/nulstil-adgangskode" element={<ResetPassword />} />
            <Route path="/opret-virksomhed" element={<CreateCompany />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/indstillinger"
              element={
                <ProtectedRoute>
                  <Settings isDataModified={isDataModified} setIsDataModified={setIsDataModified} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/form"
              element={
                <ProtectedRoute>
                  <FormPage isDataModified={isDataModified} setIsDataModified={setIsDataModified} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rapport"
              element={
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;



