import React from 'react';
import { Typography, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactMarkdown from 'react-markdown';

const TipSection = ({ activeStep, steps }) => {
  if (!steps || steps.length === 0 || 'error' in steps) return null;
  const area = steps[activeStep];
  if (!area || area.id === undefined) return null;

  return (
    <div>
      <Box display="flex" alignItems="center">
        <InfoOutlinedIcon color="primary" />
        <Typography variant="h5" color="primary" style={{ padding: '10px' }}>
          Tip
        </Typography>
      </Box>
      <ReactMarkdown className="markdown-content">
        {area.tip || 'Ingen tip tilgængelig'}
      </ReactMarkdown>
    </div>
  );
};

export default TipSection;